<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <div>
      <!-- is-date-show
        :date-selected="false"
        :date-validation="false" -->
      <header-filters
        class-name="dashboard"
        :product-options="[{ id: 'fleet', value: 'FLEET' }]"
        selected-product="fleet"
        :filter-names="['route']"
        @onChange="onFiltersChange"
      >
        <div class="div-class">
          <v-card>
            <v-autocomplete
              v-model="options.vehicle_ids"
              :loading="isVehicleList"
              :items="vehicleList"
              item-value="id"
              item-text="name"
              clearable
              multiple
              :label="$t('select_vehicles')"
              filled
              @change="onVehicleChange"
            >
              <template v-slot:selection="data">
                <span
                  v-if="data.index === 0"
                  class="selected"
                >
                  <span>{{ data.item.name }}</span>
                </span>
                <span
                  v-if="data.index === 1"
                  class="grey--text text-caption"
                >
                  (+{{ options.vehicle_ids.length - 1 }}... )
                </span>
              </template>
            </v-autocomplete>
          </v-card>
        </div>
        <v-card>
          <!-- v-model="dateMenu" -->
          <v-menu
            ref="dateMenu"
            :close-on-click="true"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormatted"
                dense
                label="Date range"
                readonly
                v-bind="attrs"
                filled
                v-on="on"
              />
              <!-- @blur="date = parseDate(dateFormatted)" -->
            </template>
            <v-date-picker
              v-model="date"
              range
              @input="onDateChange"
            />
          </v-menu>
        </v-card>
      </header-filters>
    </div>
    <!--  -->
    <!-- Summary Section -->
    <summary-section
      v-if="list?.routes"
      :items="list"
    />
    <!-- Summary Section -->
    <!-- <section v-if="list">
      <v-card>
        <v-card-text>
          <div class="content">
            <div class="summary">
              <div class="headertag">Summary</div>
              <div class="summary-tags">
                <div>Name</div>
              </div>
              <div class="summary-tags">
                <div>Distance</div>
              </div>
              <div class="summary-tags">
                <div>Cost</div>
              </div>
              <div class="summary-tags">
                <div>Time</div>
              </div>
            </div>
            <div
              v-if="list.routes"
              class="vehicle-info"
            >
              <div class="vehicle-tags justify-center">
                <div class="header">Suggested Route</div>
              </div>
              <div class="vehicle-tags justify-center">
                <div>{{ list?.routes?.name }}</div>
              </div>
              <div class="vehicle-tags justify-center">
                <div>{{ list?.routes?.distance }}</div>
              </div>
              <div class="vehicle-tags justify-center">
                <div>{{ list?.routes?.cost }}</div>
              </div>
              <div class="vehicle-tags justify-center">
                <div>{{ list?.routes?.time }}</div>
              </div>
            </div>
            <div
              v-for="(row, i) in list?.vehicles"
              :key="i"
              class="vehicle-info"
            >
              <div class="vehicle-tags justify-center">
                <div class="header">Vehicle-{{ i + 1 }}</div>
              </div>
              <div class="vehicle-tags justify-center">
                <div class="">
                  {{ row?.vehicle_data?.name }}
                </div>
              </div>
              <div class="vehicle-tags justify-center">
                <div class="">
                  {{ row?.vehicle_data?.distance }}
                </div>
              </div>
              <div class="vehicle-tags justify-center">
                <div class="">
                  {{ row?.vehicle_data?.cost }}
                </div>
              </div>
              <div class="vehicle-tags justify-center">
                <div class="">
                  {{ row?.vehicle_data?.time }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </section> -->

    <section>
      <v-card :loading="isLoading">
        <compare-route />
      </v-card>
    </section>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DashboardDashboard",
  components: {
    HeaderFilters: () => import("@/layouts/section/MainFilters.vue"),
    compareRoute: () => import("./utils/CompareRoute.vue"),
    summarySection: () => import("./utils/SummarySection.vue"),
  },
  data() {
    return {
      options: {},
      dateMenu: false,
      date: [],
      //
      selected_imeis: [],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isVehicleList: "vehicles/getIsLoadingVehicles",
      vehicleList: "vehicles/getVehiclesList",
      //
      isLoading: "routeListing/getIsLoading",
      list: "routeListing/getCompareRoute",
    }),
    dateFormatted() {
      return this.date.join(" ~ ");
    },
  },
  watch: {
    // "options.tenant_id": {
    //   handler: async function () {
    //     this.getVehicleList();
    //   },
    // },
  },
  mounted() {
    // for vehicles
    if (this.$route?.query?.vehicle_ids) {
      let id = this.$route?.query?.vehicle_ids;
      if (typeof id === "object") {
        this.options.vehicle_ids = this.$route?.query?.vehicle_ids.map((r) =>
          parseInt(r)
        );
      } else {
        this.options.vehicle_ids = [parseInt(id)];
      }
    }
    // for IMEIS
    if (this.$route?.query?.imeis) {
      this.selected_imeis = this.$route?.query?.imeis;
    }
    // for Date Range
    if (this.$route?.query?.dateRange) {
      this.date = this.$route.query.dateRange;
    }
  },
  beforeDestroy() {
    const params = {
      name: "route_comparison",
      // filters: this.options,
      filters: {
        ...this.options,
        dateRange: this.date,
        imeis: this.selected_imeis,
      },
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      if (
        this.options.route_id &&
        this.options.vehicle_ids &&
        this.date.length > 1
      ) {
        // const imeis
        // this.selected_imeis = this.vehicleList
        //   .filter((r) => this.options.vehicle_ids.includes(r.id))
        //   .map((r) => r.iot_device_imei);
        const params = {
          ...this.options,
          date: this.date,
          imeis: this.selected_imeis,
        };

        await this.$store
          .dispatch("routeListing/compareRoute", params)
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
          });
      }
    },
    onVehicleChange() {
      this.selected_imeis = this.vehicleList
        .filter((r) => this.options.vehicle_ids.includes(r.id))
        .map((r) => r.iot_device_imei);

      this.fetchData();
    },
    onFiltersChange(v) {
      //   if (v.tenant_id !== this.options.tenant_id) {
      //     delete this.options.md_model_id;
      //   }
      delete this.options.product;
      delete this.options.fleetId;
      delete this.options.oem_b2b_ids;
      delete this.options.tenant_id;
      this.options = { ...this.options, ...v };
      // this.options = { ...v };
      if (v.tenant_id) {
        this.onTenantChange();
      }
      this.fetchData();
    },
    async onTenantChange() {
      this.getVehicleList();
      //   await this.$store.dispatch("mdModels/list", this.options);
      // if (this.authUser.isAdmin || this.authUser.user_nature == "fleet") {
      // }
    },
    async getVehicleList() {
      const params = { ...this.options, compare_route: true };
      await this.$store.dispatch("vehicles/list", params);
    },
    // onDateChange(v) {
    //   this.options.date = v;
    //   this.fetchData();
    // },

    onDateChange(v) {
      if (v.length > 1) {
        this.dateMenu = false;
        this.fetchData();
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.content
  display: flex
  gap: 1rem
  font-size: 13px
  .summary
    flex-basis: 200px
    .headertag
      font-weight: 600
      font-size: 20px
      margin-bottom: 0.5rem
    .summary-tags
      display: flex
      align-items: center
      justify-content: space-between

  .vehicle-info
    border-left: 1px solid gray
    padding-left: 0.5rem
    .vehicle-tags
      display: flex
      align-items: center
      justify-content: space-between
      .header
        font-weight: 600
        margin-bottom: 0.5rem

:deep .v-autocomplete
  .v-select__selections
    .selected
      width: 74%
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

@media only screen and (max-width: 640px)
  .div-class
    width: 100%
</style>
<style lang="sass">
.div-class
  .v-select__selections
    flex-flow: nowrap

.div-class
  .v-card
    .v-input
      padding-top: 0px
      margin-top: 0px

.v-text-field--filled:not(.v-text-field--single-line) input
  font-size: 0.8125rem !important
</style>
/* Small devices (portrait tablets and large phones, 600px and up) */ @media
only screen and (min-width: 600px) { .box { width: 250px !important; } }
